<template>
  <div>
    <div class="d-flex flex-row mb-6">
      <p class="text-2xl mb-6">GA via JS Test</p>
    </div>

    <textarea cols="80" rows="20" id="query-output"></textarea>
  </div>
</template>
<script>
// Replace with your client ID from the developer console.
var CLIENT_ID =
  "196250665111-ahp0db1m72fkvu4eg6fds2so3vorq2f0.apps.googleusercontent.com";
var CLIENT_SECRET = "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";

// Set authorized scope.
var SCOPES = ["https://www.googleapis.com/auth/analytics.readonly"];

function queryAccounts() {
  // Load the Google Analytics client library.
  gapi.client.load("analytics", "v3").then(function () {
    // Get a list of all Google Analytics accounts for this user
    gapi.client.analytics.management.accounts.list().then(handleAccounts);
  });
}

function handleAccounts(response) {
  // Handles the response from the accounts list method.
  console.log("handleAccounts(response)", response);
  if (response.result.items && response.result.items.length) {
    // Get the first Google Analytics account.
    var firstAccountId = response.result.items[0].id;

    // Query for properties.
    queryProperties(firstAccountId);
  } else {
    console.log("No accounts found for this user.");
  }
}

function queryProperties(accountId) {
  // Get a list of all the properties for the account.
  gapi.client.analytics.management.webproperties
    .list({ accountId: accountId })
    .then(handleProperties)
    .then(null, function (err) {
      // Log any errors.
      console.log(err);
    });
}

function handleProperties(response) {
  // Handles the response from the webproperties list method.
  if (response.result.items && response.result.items.length) {
    // Get the first Google Analytics account
    var firstAccountId = response.result.items[0].accountId;

    // Get the first property ID
    var firstPropertyId = response.result.items[0].id;

    // Query for Views (Profiles).
    queryProfiles(firstAccountId, firstPropertyId);
  } else {
    console.log("No properties found for this user.");
  }
}

function queryProfiles(accountId, propertyId) {
  // Get a list of all Views (Profiles) for the first property
  // of the first Account.
  gapi.client.analytics.management.profiles
    .list({
      accountId: accountId,
      webPropertyId: propertyId,
    })
    .then(handleProfiles)
    .then(null, function (err) {
      // Log any errors.
      console.log(err);
    });
}

function handleProfiles(response) {
  // Handles the response from the profiles list method.
  if (response.result.items && response.result.items.length) {
    // Get the first View (Profile) ID.
    var firstProfileId = response.result.items[0].id;

    // Query the Core Reporting API.
    queryCoreReportingApi(firstProfileId);
  } else {
    console.log("No views (profiles) found for this user.");
  }
}

function queryCoreReportingApi(profileId) {
  // Query the Core Reporting API for the number sessions for
  // the past seven days.
  gapi.client.analytics.data.ga
    .get({
      ids: "ga:" + profileId,
      "start-date": "7daysAgo",
      "end-date": "today",
      metrics: "ga:sessions",
    })
    .then(function (response) {
      var formattedJson = JSON.stringify(response.result, null, 2);
      document.getElementById("query-output").value = formattedJson;
    })
    .then(null, function (err) {
      // Log any errors.
      console.log(err);
    });
}

// Add an event listener to the 'auth-button'.
//document.getElementById("auth-button").addEventListener("click", authorize);

export default {
  name: "GaViaJsTest",
  created() {},
  data() {
    return {};
  },
  methods: {
    authorize(event) {
      // Handles the authorization flow.
      // `immediate` should be false when invoked from the button click.
      var useImmdiate = event ? false : true;
      var authData = {
        client_id: CLIENT_ID,
        scope: SCOPES,
        immediate: useImmdiate,
      };
      console.log("useImmdiate", useImmdiate);
      console.log("authData", authData);
      console.log("gapi", gapi);

      gapi.auth.authorize(authData, function (response) {
        console.log("authorize(event) gapi.auth.authorize", response);
        var authButton = document.getElementById("auth-button");
        console.log("authButton", authButton);
        if (response.error) {
          authButton.hidden = false;
        } else {
          authButton.hidden = true;
          queryAccounts();
        }
      });
    },
  },
  computed: {},
  mounted() {
    let my_awesome_script = document.createElement("script");
    my_awesome_script.setAttribute(
      "src",
      "https://apis.google.com/js/client.js"
    );
    document.head.appendChild(my_awesome_script);
    my_awesome_script.onload = this.authorize();
  },
};
</script>

<style scoped></style>
